import React from "react"
import "./blogHero.scss"

export const BlogHero = () => {
    return (
        <div id="blogHero">
            <div className={"contained"}></div>
        </div>
    )
}
