import React from "react"
import { graphql } from "gatsby"
import { AnimatedSlideIn } from "../components/animatedSlideIn/animatedSlideIn"
import { BlogHero } from "../components/blogHero/blogHero"
import { MainLayout } from "../components/mainLayout/mainLayout"
import { PostLink } from "../components/postLink/post-link"
import { SEO } from "../components/seo/seo"

const BlogPage = ({
    data: {
        allMarkdownRemark: { edges },
    },
}) => {
    const Posts = edges
        .filter((edge) => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
        .map((edge) => (
            <PostLink
                img={
                    edge.node.frontmatter.featuredImage.childImageSharp
                        .gatsbyImageData
                }
                className={"post"}
                key={edge.node.id}
                post={edge.node}
            />
        ))

    return (
        <MainLayout>
            <SEO title="Blog" />
            <BlogHero />
            <AnimatedSlideIn>
                <div className={"blog contained"}>{Posts}</div>
            </AnimatedSlideIn>
        </MainLayout>
    )
}

export default BlogPage

export const pageQuery = graphql`
    {
        allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
            edges {
                node {
                    id
                    excerpt(pruneLength: 250)
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        author
                        slug
                        tags
                        title
                        featuredImage {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 800
                                    layout: CONSTRAINED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`
