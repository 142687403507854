import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import "./post-link.scss"

export const PostLink = ({ post, className, img }) => (
    <div className={className}>
        <Link className={"post-link"} to={`${post.frontmatter.slug}/`}>
            <div>
                <GatsbyImage
                    image={img}
                    imgStyle={{
                        objectFit: "contain",
                    }}
                    alt="icon"
                />
            </div>
            <div className="card-detail">
                <div className={"title"}>{post.frontmatter.title}</div>
                <div style={{ marginTop: "20px" }}>
                    <p>{post.frontmatter.author}</p>
                    <p>{post.frontmatter.date}</p>
                </div>
            </div>
        </Link>
    </div>
)
